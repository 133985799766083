import React, { Component } from 'react'
import Helmet from 'react-helmet'

import '../styles/index.scss'

import Layout from '../components/layout'
import { Slider } from '../components/slider_header'
import { SliderClient } from '../components/slider_client'
import { Footer } from '../components/footer'
import { Instagram } from '../components/instagram'
// import { Link } from 'gatsby'

export class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title="Barlavento - Home" />

        {/* HEADER */}
        <header className="home_header" id="anchor_home">
          <Slider />
        </header>

        {/* SECTION 1 */}
        <section className="home_section1" id="section1">
          <img
            src={require('../assets/sec_one_pic.png')}
            className="sec_one_pic"
            alt="sec_one_pic"
          />

          <div className="sec_one_background" />

          <div className="sec_one_quadro" />

          <dir className="blanc" />

          <img
            src={require('../assets/arrow_long.svg')}
            className="arrow_home"
            alt="arrow_home"
          />

          <div className="sec_one_desc">
            Somos estrategistas financeiros e parceiros do negócio. Por meio de
            processos bem definidos e dos conhecimentos do nosso time,
            entregamos um maior entendimento sobre sua empresa e segurança na
            tomada de decisões.
          </div>

          <h1 id="sobre_nos">SOBRE NÓS</h1>
        </section>

        {/* SECTION 2 */}
        {/* <section className="home_section2" id="section2">
          <h1 className="percent forty_five">45%</h1>

          <p className="forty_five_desc">
            Nossos clientes aumentaram em média 45% do seu faturamento em 2017
            comparado com 2016;
          </p>

          <div className="tracinho1" />

          <h1 className="percent sixty">60%</h1>

          <p className="sixty_desc">
            Nossos clientes reduziram seu custo financeiro em mais de 60%;
          </p>

          <div className="tracinho2" />

          <h1 className="percent fifty">50%</h1>

          <p className="fifty_desc">
            Receberam em média 50% da sua carteira de inadimplentes;
          </p>
        </section> */}

        {/* SECTION 3 */}
        <section className="section3" id="section3">
          <h1 className="siga_nos">SIGA-NOS NO INSTAGRAM</h1>

          <a
            href="https://www.instagram.com/barlaventoestrategia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h2 className="at_barla">@barlaventoestrategia</h2>
          </a>

          <img
            src={require('../assets/instagram.svg')}
            className="instagram_logo"
            alt="instagram_logo"
          />

          <div className="fotos">
            <Instagram />
          </div>

          <div className="sec_three_background" />
        </section>

        <div id="anchor_section4" />

        {/* SECTION 4 */}
        <section className="section4" id="section4">
          <h1 className="nossos_clientes">NOSSOS CLIENTES</h1>

          <SliderClient />
        </section>

        {/* SECTION 5 */}
        <section className="section5" id="section5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.1804714693517!2d-47.91396828472232!3d-15.794435189051232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3a8f94a13fe5%3A0x1c1ae579cce49c2b!2sBusiness+Park+Brasilia+Center!5e0!3m2!1sen!2sca!4v1544559380081"
            frameBorder="0"
            title="Localização"
          />
        </section>

        {/* FOOTER */}
        <section className="home_footer">
          <Footer />
        </section>
      </Layout>
    )
  }
}

export default IndexPage
