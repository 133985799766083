import React, { Component } from 'react'
import Item from './Item'
import Imagem from './imagem'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import './Slider_client.scss'

const data = [
  {
    picture: 'soreparos1.png',
    title: 'SÓ REPAROS',
  },
  {
    picture: 'cni.jpeg',
    title: 'CNI',
  },
  {
    picture: 'kluster.png',
    title: 'KLUSTER',
    subtitle: 'MOTO HUB',
  },
  {
    picture: 'moradamineira2.png',
    title: 'MORADA MINEIRA',
  },
  {
    picture: 'solo_producoes2.png',
    title: 'SOLO',
  },
  {
    picture: 'avocado.png',
    title: 'AVOCADO',
  },
  {
    picture: 'manifesto.png',
    title: 'MANIFESTO',
  },
]

export class SliderClient extends Component {
  state = {
    data: data,
    active: 0,
    direction: '',
  }

  slideLeft = () => {
    var newActive = this.state.active
    newActive--

    this.setState({
      active: newActive < 0 ? this.state.data.length - 1 : newActive,
      direction: 'left',
    })
  }

  slideRight = () => {
    var newActive = this.state.active

    this.setState({
      active: (newActive + 1) % this.state.data.length,
      direction: 'right',
    })
  }

  generateItems = () => {
    var data_array = []
    var level

    for (var i = this.state.active - 2; i < this.state.active + 3; i++) {
      var index = i

      if (i < 0) {
        index = this.state.data.length + i
      } else if (i >= this.state.data.length) {
        index = i % this.state.data.length
      }

      level = this.state.active - i
      data_array.push(
        <Item key={index} level={level} data={this.state.data[index]} />
      )
    }

    return data_array
  }

  generateNumbers = () => {
    var number_array = []
    var level

    for (var i = this.state.active - 2; i < this.state.active + 3; i++) {
      var index = i

      if (i < 0) {
        index = this.state.data.length + i
      } else if (i >= this.state.data.length) {
        index = i % this.state.data.length
      }

      level = this.state.active - i
      const classNumber = 'numero' + level

      number_array.push(
        <h3 className={classNumber} key={index}>
          {`0${data.indexOf(this.state.data[index]) + 1}`}
        </h3>
      )
    }

    return number_array
  }

  generatePictures = () => {
    var picture_array = []
    var level

    for (var i = this.state.active - 2; i < this.state.active + 3; i++) {
      var index = i

      if (i < 0) {
        index = this.state.data.length + i
      } else if (i >= this.state.data.length) {
        index = i % this.state.data.length
      }

      level = this.state.active - i

      picture_array.push(
        <Imagem key={index} level={level} data={this.state.data[index]} />
      )
    }

    return picture_array
  }

  render() {
    const wrapperClass = 'wrapper-' + this.state.direction

    return (
      <div className="slider_client">
        <div className={wrapperClass}>
          <CSSTransitionGroup
            transitionName="frames"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {this.generateItems()}
          </CSSTransitionGroup>
        </div>

        <div className={wrapperClass}>
          <CSSTransitionGroup
            transitionName="pic_frames"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
          >
            {this.generatePictures()}
          </CSSTransitionGroup>
        </div>

        {this.generateNumbers()}

        {/* SETAS */}
        <img
          src={require('../../assets/right_arrow.svg')}
          onClick={() => this.slideRight()}
          className="right_arrow"
          alt="right_arrow"
        />

        <img
          src={require('../../assets/left_arrow.svg')}
          onClick={() => this.slideLeft()}
          className="left_arrow"
          alt="left_arrow"
        />

        <div className="tracinho" />
        <div className="oval" />
      </div>
    )
  }
}

export default SliderClient
