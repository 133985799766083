import React from 'react'

import { AnchorLink as Link } from 'gatsby-plugin-anchor-links'

import './Nav.scss'

export default props => (
  <nav id="nav" className={props.sticky ? 'navTwo' : 'navOne'}>
    <img
      src={require('../../assets/logo_small.svg')}
      className="logo_small"
      alt="logo_small"
    />
    <div className="navBox">
      <Link to="/#anchor_home" title="SOBRE NÓS" className="menu" />
      <Link to="/services#anchor_services" title="SERVIÇOS" className="menu" />
      <Link to="/#anchor_section4" title="CLIENTES" className="menu" />
      <Link to="/services#contact_anchor" title="CONTATO" className="menu" />

      <a href="https://medium.com/barlavento" className="menu">
        BLOG
      </a>
    </div>
    <div className="barra" />
  </nav>
)
