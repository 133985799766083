import React, {Fragment} from 'react'
import './Instagram.scss'
import { StaticQuery, graphql } from 'gatsby'


export default () => 

    <div className='frame'>
        <StaticQuery
            query={graphql`
                query InstagramPosts {
                    allInstagramContent {
                        edges {
                            node {
                                link
                                images {
                                    standard_resolution {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            `}

            render={data => (
                <Fragment>
                    {
                        data.allInstagramContent.edges.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className="wrapper_image_insta"
                                >
                                    <a 
                                        href={item.node.link} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div 
                                            style={{backgroundImage: `url(${item.node.images.standard_resolution.url})`}}
                                            className='insta'
                                            alt='insta'
                                        />    
                                    </a>
                                </div>
                            )
                        })
                    }
                </Fragment>
            )}
        />
    </div>

