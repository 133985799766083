module.exports = [{
      plugin: require('/builds/dev_squad/barlavento-www/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/dev_squad/barlavento-www/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/dev_squad/barlavento-www/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
