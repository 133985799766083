import React from 'react'
import { Link } from 'gatsby'

import './Footer.scss'

const style = {
  opacity: 0.5,
}

const activeStyle = {
  opacity: 1,
}

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="fundo">
          <div className="tracinho" />

          <Link
            to="/"
            className="sobre_nos"
            style={style}
            activeStyle={activeStyle}
          >
            SOBRE NÓS
          </Link>

          <Link
            to="/services"
            className="servicos"
            style={style}
            activeStyle={activeStyle}
          >
            SERVIÇOS
          </Link>

          <Link
            to="#anchor_section4"
            className="clientes"
            style={style}
            activeStyle={activeStyle}
          >
            CLIENTES
          </Link>

          <Link
            to="/services/#section3"
            className="contato"
            style={style}
            activeStyle={activeStyle}
          >
            CONTATO
          </Link>

          <div className="endereco">
            <span style={{ fontWeight: 'bold' }}>Brasília, DF, 70610-410 </span>
            Sala 138 - Ed Parque Brasília, St. de Indústrias Gráficas, Quadra 3
          </div>

          <h3 className="newsletters">EMAIL NEWSLETTERS</h3>
          <p>Receba nossos informativos, livros e conteúdos exclusivos</p>

          <img
            src={require('../../assets/logo2.svg')}
            className="logo_2"
            alt="logo_2"
          />

          <form
            id="footer_form"
            action="https://formspree.io/atendimento@barlaventomentoria.com"
            method="POST"
          >
            <label htmlFor="email">
              <input
                type="email"
                placeholder="E-mail"
                name="email"
                className="email"
              />
            </label>

            <button type="submit" className="button_submit">
              <img
                src={require('../../assets/email_logo.svg')}
                className="email_logo"
                alt="email_logo"
              />
            </button>
          </form>

          <hr />

          <a
            href="https://www.facebook.com/barlaventomentoria/"
            target="_blank"
          >
            <img
              src={require('../../assets/f_logo.svg')}
              className="f_logo"
              alt="f_logo"
            />
          </a>
          <img
            src={require('../../assets/t_logo.svg')}
            className="t_logo"
            alt="t_logo"
          />
          <a
            href="https://www.instagram.com/barlaventoestrategia/"
            target="_blank"
          >
            <img
              src={require('../../assets/i_logo.svg')}
              className="i_logo"
              alt="i_logo"
            />
          </a>
        </div>
      </footer>
    )
  }
}

export default Footer
