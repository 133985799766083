import React, { Component } from 'react'

import Layout from '../components/layout'
import Helmet from 'react-helmet'

import { Footer } from '../components/footer'

import '../styles/services.scss'

class Services extends Component {
  render() {
    return (
      <Layout>
        <Helmet title="Barlavento - Services" />

        {/* HEADER */}
        <header className="services_header" id="anchor_services">
          <img
            src={require('../assets/logo1.svg')}
            className="logo"
            alt="logo"
          />

          <h1 className="servicos">SERVIÇOS</h1>

          <div className="tracinho" />

          <p>Como podemos te ajudar</p>

          <div className="wrapper_image_services">
            <div className="services_header_pic" alt="services_header_pic" />
          </div>
        </header>

        {/* SECTION 1 */}
        <section className="services_section1">
          <div className="div1">
            <h1 className="mentoria">MENTORIA FINANCEIRA</h1>

            <div className="serv_quadro_1" />

            <p className="mentoria-txt">
              A Mentoria Financeira tem como objetivo central prestar suporte ao
              empreendedor na tomada de decisões. Junto a ele buscamos entender
              os contextos (financeiro, operacional e de mercado) da empresa
              para planejar e auxiliar na implementação de mudanças para uma
              melhor gestão financeira. Em paralelo, vamos entendendo e
              avaliando os pontos chave de acompanhamento da empresa, as suas
              alavancas. A partir dessas informações é possível identificar
              gargalos, pontos de ineficiência e dar visibilidade sobre margens,
              fluxo de caixa e capacidade operacional. Tendo feito essas etapas
              conseguimos projetar as otimizações a serem aplicadas e
              acompanhamos as implementações provendo suporte, aplicando e
              transmitindo nosso conhecimento em todas as etapas do processo.
            </p>

            <dir className="blanc_1" />

            <img
              src={require('../assets/arrow_long.svg')}
              className="arrow_1"
              alt="arrow_1"
            />

            <img
              src={require('../assets/art_1.svg')}
              className="art_1"
              alt="art_1"
            />
          </div>

          <div className="div2">
            <img
              src={require('../assets/art_2.svg')}
              className="art_2"
              alt="art_2"
            />

            <h1 className="estruturacao">ESTUDOS ECONÔMICOS</h1>

            <div className="serv_quadro_2" />

            <p className="um-trabalho-celere">
              Estudos econômicos com o objetivo de avaliar a sustentabilidade
              financeira de uma ideia. Seja uma uma nova linha de receita, uma
              nova unidade ou um novo negócio conseguimos avaliar e sugerir
              caminhos para a sua sustentabilidade financeira.
            </p>
          </div>

          <dir className="blanc_2" />

          <img
            src={require('../assets/arrow_long.svg')}
            className="arrow_2"
            alt="arrow_2"
          />

          <div className="div3">
            <h1 className="avaliacao">INTELIGÊNCIA FINANCEIRA</h1>

            <div className="serv_quadro_3" />

            <p className="avaliacao-txt">
              Trabalhos específicos com o objetivo de trazer inteligência
              financeira aos diferentes setores da sua empresa. Seja para
              aumentar a eficiência da sua área de projetos ou para uma melhor
              gestão de estoque, analisamos o contexto operacional e trazemos um
              ponto de vista racional e financeiro para aumentar a eficiência e
              os resultados do setor.
            </p>

            <dir className="blanc_3" />

            <img
              src={require('../assets/arrow_long.svg')}
              className="arrow_3"
              alt="arrow_3"
            />

            <img
              src={require('../assets/art_3.svg')}
              className="art_3"
              alt="art_3"
            />
          </div>

          <div id="contact_anchor" />

          <h1 className="entre-em-contato">ENTRE EM CONTATO</h1>
        </section>

        {/* SECTION 2 */}
        <section className="services_section2">
          <div className="sec2_background" />

          <form
            id="intro-form"
            action="https://formspree.io/atendimento@barlaventomentoria.com"
            method="POST"
          >
            <label htmlFor="intro-name" className="contact-name">
              NOME
            </label>

            <input
              type="text"
              id="name"
              name="nome"
              placeholder="João da Silva"
            />

            <label htmlFor="intro-tel" className="contact-telefone">
              TELEFONE
            </label>

            <input
              type="tel"
              id="tel"
              name="telefone"
              placeholder="(61) 9988-0099"
            />

            <label htmlFor="intro-email" className="contact-email">
              E-MAIL
            </label>

            <input
              type="email"
              name="email"
              id="email"
              placeholder="seu@email.com.br"
            />

            <label htmlFor="intro-email" className="contact-mensagem">
              MENSAGEM
            </label>

            <textarea
              id="mensagem"
              name="mensagem"
              placeholder="Escreva sua mensagem aqui"
            />

            <button type="submit" className="button-form">
              ENVIAR
            </button>
          </form>
        </section>

        {/* SECTION 3 */}
        <section className="services_section3" id="section3">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.1804714693517!2d-47.91396828472232!3d-15.794435189051232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3a8f94a13fe5%3A0x1c1ae579cce49c2b!2sBusiness+Park+Brasilia+Center!5e0!3m2!1sen!2sca!4v1544559380081"
            frameBorder="0"
            title="Localização"
          />
        </section>

        {/* FOOTER */}
        <section className="services_footer">
          <Footer />
        </section>
      </Layout>
    )
  }
}

export default Services
